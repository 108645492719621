import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { ISupplierWedding } from './Weddings.types';
import { Enquiries } from './Weddings/Enquiries';
import { limit, type QueryConstraint, serverTimestamp, where } from 'firebase/firestore';

@Identifiable
@Timestampable
@Untrackable
export class Wedding extends AbstractDocument<ISupplierWedding> {
  readonly collections = {
    Enquiries: new Enquiries(this),
  };

  get supplier() {
    return Suppliers._.getById(this.reference.parent.parent.id);
  }

  /**
   * Acknowledges a couple-initiated booking.
   */
  async acknowledge() {
    return this.set({
      timestamps: {
        coupleBookingAcknowledged: serverTimestamp(),
      },
    });
  }

  /**
   * Returns whether this enquiry is currently locked or not, depending on whether:
   *
   * 1) The supplier pay-per-enquiry feature flag is enabled, and
   * 2) Any enquiry originating from the same wedding has already been revealed.
   */
  async isLocked() {
    const data = await this.get(true);

    if ((await this.isPayPerEnquiry(data)) !== true) {
      return false;
    }

    if ((await this.isRevealed(data)) === true) {
      return false;
    }

    return this.supplier.Admins.admin.payPerEnquiry;
  }

  /**
   * True if an enquiry has been sent while supplier had payPerEnquiry.
   */
  async isPayPerEnquiry(data?: ISupplierWedding) {
    if (data == null) {
      data = await this.get(true);
    }

    return data?.flags?.isPayPerEnquiry ?? false;
  }

  async isRevealed(data?: ISupplierWedding) {
    if (data == null) {
      data = await this.get(true);
    }

    return data?.flags?.revealed === true || data?.source === 'widget';
  }
}

export class Weddings extends AbstractCollection<Wedding, ISupplierWedding> {
  static definitions = {
    _: {} as ISupplierWedding,
  };

  static path = 'weddings';

  constructor(document: Supplier) {
    super(document.collection(Weddings.path), Wedding);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }

  /**
   * Queries supplier wedding enquiries by wedding ID and returns a single document.
   */
  async getByWeddingId(wedding: string): Promise<ISupplierWedding | undefined> {
    return Object.values(await this.query([where('wedding', '==', wedding), limit(1)]).get()).shift();
  }

  /**
   * Queries supplier wedding enquiries that have been marked as booked by the couple, but not yet acknowledged.
   */
  getUnacknowledgedBookings() {
    const constraints: QueryConstraint[] = [
      where('flags.bookedByCouple', '==', true),
      where('timestamps.coupleBookingAcknowledged', '==', null),
    ];

    return this.query(constraints);
  }
}
