import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IPackage } from './Packages.types';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
@Untrackable
export class Package extends AbstractDocument<IPackage> {
  readonly collections = {};

  readonly translatable = (data: IPackage): IPackage['_translations'][string] => ({
    description: data.description,
    name: data.name,
  });
}

export class Packages extends AbstractCollection<Package, IPackage> {
  static definitions = {
    _: {} as IPackage,
  };

  static path = 'packages';

  constructor(document: Supplier) {
    super(document.collection(Packages.path), Package);
  }

  static new<M extends typeof Packages.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IPackage> = {};

    if (key !== '_' && key in Packages.definitions) {
      result = (result[key as keyof Omit<typeof Packages.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
