export const DEALS_SECTION_ID = 'special-offers-section';
export const ABOUT_ME_SECTION_ID = 'about-me-section';
export const PRICING_SECTION_ID = 'pricing-section';
export const FEATURES_SECTION_ID = 'features-section';
export const DESCRIPTION_SECTION_ID = 'supplier-description-section';
export const REAL_WEDDINGS_SECTION_ID = 'real-weddings-section';
export const REVIEWS_SECTION_ID = 'supplier-testimonials';
export const LOCATION_SECTION_ID = 'supplier-location-section';
export const FAQ_AWARDS_PREFERRED_SUPPLIERS_SECTION_ID = 'faq-awards-preferred-suppliers-section';

// subsections of FAQ_AWARDS_PREFERRED_SUPPLIERS_SECTION_ID
export const FAQ_SECTION_ID = 'faq-section';
export const AWARDS_SECTION_ID = 'awards-section';
export const PREFERRED_SUPPLIERS_SECTION_ID = 'preferred-suppliers-section';

// subsections of DEALS_SECTION_ID
export const SPECIAL_OFFERS_HEADER_ID = 'special-offers-header';
export const SPECIAL_OFFER_POPUP_ID = 'special-offer-popup-inner';
