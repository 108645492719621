import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IFeedback } from './Feedback.types';
import { limit, orderBy, where } from 'firebase/firestore';

@Identifiable
@Timestampable
@Untrackable
export class Feedback extends AbstractDocument<IFeedback> {
  readonly collections = {};

  readonly translatable = (data: IFeedback): IFeedback['_translations'][string] => ({
    body: data.body,
    response: {
      message: data.response?.message,
    },
    title: data.title,
  });
}

export class FeedbackCollection extends AbstractCollection<Feedback, IFeedback> {
  static path = 'feedback';

  constructor(document: Supplier) {
    super(document.collection(FeedbackCollection.path), Feedback);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }

  async getStructuredData(max: number, types: IFeedback['type'][] = ['review', 'testimonial']) {
    const result = {
      aggregateRating: {
        '@type': 'AggregateRating',
        bestRating: 5,
        ratingValue: 0,
        reviewCount: 0,
        worstRating: 1,
      },
      review: {
        '@type': 'Review',
        abstract: '',
        author: {
          '@type': 'Person',
          name: '',
        },
        reviewBody: '',
        reviewRating: {
          '@type': 'Rating',
          bestRating: 5,
          ratingValue: 0,
          worstRating: 1,
        },
      },
    };

    const documents = await this.query([where('type', 'in', types), orderBy('createdAt', 'desc'), limit(max)]).get(true);

    for (const key in documents) {
      const review = documents[key];

      /**
       * Do we want to skip non-approved reviews?
       */
      if (review.approved === true && review.stars != null) {
        result.aggregateRating.ratingValue += review.stars;
        result.aggregateRating.reviewCount++;

        if (result.review.reviewRating.ratingValue === 0) {
          result.review.abstract = review.title;
          result.review.author.name = review.name;
          result.review.reviewBody = review.body;
          result.review.reviewRating.ratingValue = review.stars;
        }
      }
    }

    result.aggregateRating.ratingValue /= result.aggregateRating.reviewCount;

    return result;
  }
}
