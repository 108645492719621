import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { ISupplier_Type } from '../Suppliers.types';
import { Weddings } from '../Weddings';
import type { IWork } from './Works.types';
import { collectionGroup, getDocs, type Query, query, where } from 'firebase/firestore';

@Identifiable
@Timestampable
@Untrackable
export class Work extends AbstractDocument<IWork> {
  readonly collections = {};

  readonly translatable = (data: IWork): IWork['_translations'][string] => ({
    name: data.name,
  });

  /**
   * Return the wedding referenced by this work.
   */
  get wedding() {
    return Weddings._.getById(this.reference.id);
  }

  /**
   * Returns works performed by other suppliers for the same wedding.
   *
   * @param type Optionally return only a specific type of suppliers.
   */
  async suppliers(type?: ISupplier_Type) {
    const criteria = [where('id', '==', this.id), where('active', '==', true)];

    if (type != null) {
      criteria.push(where('role', '==', type));
    }

    const result: IWork[] = [];
    const snapshot = await getDocs(query(collectionGroup(this.reference.firestore, 'works') as Query<IWork>, ...criteria));

    for (const document of snapshot.docs) {
      if (/^[/]?suppliers[/][^/]+[/]works[/]/.test(document.ref.path) !== true) {
        continue;
      }

      if (document.ref.parent.parent.id === this.reference.parent.parent.id) {
        continue;
      }

      result.push(document.data());
    }

    return result;
  }
}

export class Works extends AbstractCollection<Work, IWork> {
  static definitions = {
    _: {} as IWork,
  };

  static path = 'works';

  constructor(document: Supplier) {
    super(document.collection(Works.path), Work);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
