import { AbstractCollection } from '../../../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../../../abstract/Document';
import { AdminLedger } from '../ledger';
import type { ISupplierAdminLedger } from '../ledger.types';
import { type ISupplierLedgerTransaction, ITransactionOperation } from './Transactions.types';
import type { DocumentReference } from 'firebase/firestore';

@Identifiable
@Timestampable
@Untrackable
export class Transaction extends AbstractDocument<ISupplierLedgerTransaction> {
  readonly collections = {};

  /**
   * Reverts a transaction by creating a new one with the inverse `units` value of the original transaction.
   *
   * @param user User ID performing the Rollback
   */
  async rollback(user: string) {
    const data = await this.get(true);
    const ledger = new AdminLedger(this.reference.parent.parent as DocumentReference<ISupplierAdminLedger>);

    return await ledger.createTransaction(data.product, user, -1 * data.units, ITransactionOperation.ROLLBACK, {
      transaction: data.id,
    });
  }
}

export class Transactions extends AbstractCollection<Transaction, ISupplierLedgerTransaction> {
  static definitions = {
    _: {} as ISupplierLedgerTransaction,
  };

  static path = 'transactions';

  constructor(document: AdminLedger) {
    super(document.collection(Transactions.path), Transaction);
  }
}
