import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Timestampable, Untrackable } from '../../abstract/Document';
import type { Supplier } from '../Suppliers';
import { AppsInstagram } from './Apps/instagram';
import type { ISupplierAppsInstagram } from './Apps/instagram.types';
import type { WriteBatch } from 'firebase/firestore';

type Collections = ISupplierAppsInstagram;

// String literal type (add | for more apps)
export type ISupplierAppIds = 'instagram';

@Timestampable
@Untrackable
export class App extends AbstractDocument<Collections> {
  readonly collections = {};
}

export class Apps extends AbstractCollection<App, Collections> {
  static definitions = {
    _: {} as Collections,
  };

  static path = 'apps';

  constructor(document: Supplier) {
    super(document.collection(Apps.path), App);
  }

  /**
   * Returns IDs of all connected apps existing in the collection.
   */
  async getConnectedApps() {
    const data = await this.query().get(true);
    const connectedApps: ISupplierAppIds[] = [];

    // Instagram is connected if instagram document exists and has an accessToken
    if (data.instagram && data.instagram.accessToken) {
      connectedApps.push('instagram');
    }

    return connectedApps;
  }

  get instagram() {
    return this.getById('instagram');
  }

  getById(key: 'instagram'): AppsInstagram & AppsInstagram['collections'];
  getById(key: 'instagram', batch?: WriteBatch) {
    let document;

    switch (key) {
      case 'instagram':
        document = new (AbstractCollection as any)(this.reference, AppsInstagram);
        break;

      default:
        throw new Error('Unknown document ID.');
    }

    return document.getById(key, batch);
  }
}
