import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IAd } from './Ads.types';

@Identifiable
@Timestampable
export class Ad extends AbstractDocument<IAd> {
  readonly collections = {};
}

export class Ads extends AbstractCollection<Ad, IAd> {
  static definitions = {
    _: {} as IAd,
  };

  static path = 'ads';

  constructor(document: Supplier) {
    super(document.collection(Ads.path), Ad);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
