import type { FetchEnrichedSupplierParams } from '@bridebook/toolbox/src/supplier/enrich/types';
import type { IEnrichedSupplier } from '@bridebook/toolbox/src/supplier/types';
import { ApiEndpoint } from 'lib/api/api-endpoint';

/**
 * @param publicId Supplier publicId
 * @param {FetchEnrichedSupplierParams} bodyParams - object to pass with POST body
 * @returns {IEnrichedSupplier}
 */
export const fetchSupplierPromise = (
  publicId: string,
  bodyParams?: FetchEnrichedSupplierParams,
): Promise<IEnrichedSupplier> =>
  fetch(ApiEndpoint.supplier.fetch(publicId), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(bodyParams || {}),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }

    throw response;
  });
