import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IOffer } from './Offers.types';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
@Untrackable
export class Offer extends AbstractDocument<IOffer> {
  readonly collections = {};

  readonly translatable = (data: IOffer): IOffer['_translations'][string] => ({
    details: data.details,
    title: data.title,
  });
}

export class Offers extends AbstractCollection<Offer, IOffer> {
  static definitions = {
    _: {} as IOffer,
  };

  static path = 'offers';

  constructor(document: Supplier) {
    super(document.collection(Offers.path), Offer);
  }

  static new<M extends typeof Offers.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IOffer> = {};

    if (key !== '_' && key in Offers.definitions) {
      result = (result[key as keyof Omit<typeof Offers.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
