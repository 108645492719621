import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IRelation } from './Relations.types';
import { where } from 'firebase/firestore';

@Identifiable
@Timestampable
@Untrackable
export class Relation extends AbstractDocument<IRelation> {
  readonly collections = {};
}

export class Relations extends AbstractCollection<Relation, IRelation> {
  static definitions = {
    _: {} as IRelation,
  };

  static path = 'relations';

  constructor(document: Supplier) {
    super(document.collection(Relations.path), Relation);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }

  get isDisabled() {
    return this.supplier.get(true).then((data) => data.flags?.hideSupplierRelations === true);
  }

  async get(active: boolean | null = null, type: string | null = null) {
    const constraints = [];

    if (active != null) {
      constraints.push(where('active', '==', active));
    }

    if (type != null) {
      constraints.push(where('type', '==', type));
    }

    return Object.values(await this.query(constraints).get(true));
  }
}
