import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IRecommendation } from './Recommendations.types';

@Identifiable
@Timestampable
@Untrackable
export class Recommendation extends AbstractDocument<IRecommendation> {
  readonly collections = {};
}

export class Recommendations extends AbstractCollection<Recommendation, IRecommendation> {
  static definitions = {
    _: {} as IRecommendation,
  };

  static path = 'recommendations';

  constructor(document: Supplier) {
    super(document.collection(Recommendations.path), Recommendation);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
