import { TFunction } from 'next-i18next';
import { ValidationError } from '@bridebook/toolbox';

export const formatReviewFormError = (e: any, t: TFunction) => {
  let error: any = e;
  if (e.prop === 'stars') {
    error = new ValidationError(
      "Oops, don't forget to give the supplier a star rating!",
      'stars',
      undefined,
      t('supplier:form.review.error.starsMissing'),
    );
  } else if (e.prop === 'email') {
    error = new ValidationError(
      "Hmm.. are you sure that's right?",
      'email',
      undefined,
      t('supplier:form.review.error.emailNotCorrect'),
    );
  }

  return error;
};
