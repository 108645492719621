import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IBadge } from './Badges.types';

@Identifiable
@Timestampable
@Untrackable
export class Badge extends AbstractDocument<IBadge> {
  readonly collections = {};

  readonly translatable = (data: IBadge): IBadge['_translations'][string] => ({
    description: data.description,
  });
}

export class Badges extends AbstractCollection<Badge, IBadge> {
  static definitions = {
    _: {} as IBadge,
  };

  static path = 'badges';

  constructor(document: Supplier) {
    super(document.collection(Badges.path), Badge);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
